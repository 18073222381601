<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>

		<Search
			class="mt-10 box-ddd"
			:search="search"
			:option="search_option"

			@click="getSearch"
			@reset="reset"
			@setSearchDateType="setSearchDateType"
			@agency="setAgency"
		>
            <select
                slot="add"
                class="pa-5-10 mr-10"

                v-model="search.type"
                @change="getSearch(1)"
            >
                <option
                    v-for="add in codes.settlement_agency_type"
                    :key="'status_' + add.code"
                    :value="add.code"
                >{{ add.code === '' ? '서비스' : add.name }}</option>
            </select>
            <select
                slot="add"
                class="pa-5-10 mr-10"

                v-model="search.state"
                @change="getSearch(1)"
            >
                <option
                    v-for="add in codes.payment_select_state"
                    :key="'status_' + add.code"
                    :value="add.code"
                >{{ add.name }}
                </option>
            </select>
		</Search>

		<div
			class="mt-10 box-ddd"
		>
			<div
				class="pa-10 bg-identify"
			>
				{{ search.sDate | transDate }} ~ {{ search.eDate | transDate }}
			</div>
			<div class=" bg-white justify-space-between">
				<div class="pa-20 flex-1 border-right bg-icon-quick">
					<div class="color-identify">배달비</div>
					<div class="mt-10 font-weight-bold size-px-16">{{ summary.quickShopCount | makeComma }}개 가맹점</div>
				</div>

				<div class="pa-20 flex-1 border-right bg-icon-rent">
					<div class="color-identify">월세</div>
					<div class="mt-10 font-weight-bold size-px-16">{{ summary.monthlyShopCount | makeComma }}개 가맹점</div>
				</div>

                <div class="pa-20 flex-1 border-right bg-icon-paper">
                    <div class="color-identify">수기결제</div>
                    <div class="mt-10 font-weight-bold size-px-16">{{ summary.manualShopCount | makeComma }}개 가맹점</div>
                </div>

                <div class="pa-20 flex-1 border-right bg-icon-calc">
					<div class="color-identify">최종 지급 금액</div>
					<div class="mt-10 font-weight-bold size-px-16">{{ summary.settlementAmount | makeComma }}원</div>
				</div>

			</div>
		</div>

		<div class="mt-10 pa-10 bg-white flex-column overflow-y-auto">
			<div class="justify-space-between">

				<div>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="item_confirm.state"
						ref="settlement_status"
					>
						<option
							v-for="(status, index) in codes.change_settlement_state"
							:key="'status_' + index"
							:value="status.code"
						>{{ status.name }}</option>
					</select>
					<button
						class="bg-identify pa-4-10 mr-10 vertical-middle"
						@click="isConfirm"
					>지급상태 변경</button>
				</div>
				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch(1)"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>

			</div>
			<table
				v-if="items.length > 0"
				class="mt-10 table table-even"
			>
				<colgroup>

					<col width="60px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />

				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>정산 기준일</th>
					<th>가맹점</th>
					<th>
                        <div class="flex-row justify-center items-center">
                            <input v-model="is_all" type="checkbox" >
                            <p class="ml-5">서비스</p>
                        </div>
                    </th>

					<th>은행</th>
					<th>계좌번호</th>
					<th>예금주명</th>
					<th>입금자명</th>
					<th>최종지급금액</th>

					<th>지급여부</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>{{ (10 * (search.page ? search.page - 1 : '0')) + index + 1 }}</td>
					<td>{{ item.settlementDate | transDate}}</td>
					<td class="text-left">{{ item.shopName }}</td>
					<td><input v-model="item.is_select" type="checkbox" class="mr-5" />{{ item.type_name }}</td>
					<td>{{ item.bankName }}</td>

					<td class="text-left">{{ item.accountNumber }}</td>
					<td class="text-left">{{ item.accountHolderName }}</td>
					<td :class="{ 'text-left' : item.depositorName }">
                        <div v-if="item.depositorName">{{ item.depositorName }}</div>
                        <div v-else>-</div>
                    </td>
                    <td class="text-right">{{ item.settlementAmount | makeComma }}원</td>
					<td :class="'color-' + item.state_color">{{ item.state_name }}</td>

					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center mt-10 top-line-identify"
			>
				<div class="text-center pa-20">
					<v-icon
						class="size-px-48 "
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>
		</div>
        <Pagination
            :program="program"
            :align="'center'"
            :options="search"

            class="mt-auto"
            @click="getSearch"
        ></Pagination>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@finish="is_excel = !is_excel"
		></Excel>

		<Modal
			:is_modal="is_modal3"
			:option="modal_option3"
			:top="true"
			:bottom="true"

			title="직접 정산 지급 상태 변경"
			width="400px"
			content_class="ptb-30"

			@close="close"
			@click="doConfirm"
			@cancel="close"
		>
			<div
				slot="modal-content"
				class="pa-10"
			>
				<div class="text-left">{{ modal_option3.content }}</div>

				<textarea
					v-model="item_confirm.reason"
					placeholder="미지급 변경시 사유를 반드시 작성해 주세요"
					class="mt-30"
				></textarea>
			</div>
		</Modal>

	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";


export default {
	name: 'shopManualSettlement',
	components: {Modal, Excel, Search, Pagination},
	data: function(){
		return {
            user: {},
			program: {
				name: '가맹점 직접 정산'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
                page: this.$route.query.page ? this.$route.query.page : 1
                , size: this.$route.query.size ? this.$route.query.size : 10
                , search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
                , search_value: ''
                , state: ''
                , type: ''
                , cycle: ''
                , sDate: this.date.getToday('-')
                , eDate: this.date.getToday('-')
                , search_date_type: 'today'
                , distributorIdx: ''
                , branchIdx: ''
                , agencyIdx: ''
                , resellerIdx: ''
                ,total_count: 0
			}
			,search_option: {
				sDate: true
				,eDate: true
				,search_type: [
                    {name: '아이디', column: 1}
                    , {name: 'MID', column: 2}
                    , {name: '가맹점명', column: 3}
                    , {name: '대표자명', column: 4}
                    , {name: '사업자번호', column: 5}
				]
				,is_search_date_type: true
				,agency: true
				,agency_sub: true
			}
			,is_excel: false
			,excel_data: {
				name: '가맹점 직접 정산 내역'
				,header: [
					{ key: 0, name: '정산기준일', column: 'settlementDate'}
					,{ key: 0, name: '가맹점', column: 'shopName'}
					,{ key: 0, name: '서비스', column: 'settlementType'}
					,{ key: 0, name: '은행', column: 'bankName'}
                    ,{ key: 0, name: '계좌번호', column: 'accountNumber'}
                    ,{ key: 0, name: '예금주명', column: 'accountHolderName'}
                    ,{ key: 0, name: '입금자명', column: 'depositorName'}
					,{ key: 0, name: '최종지급금액', column: 'settlementAmount'}
					,{ key: 0, name: '지급여부', column: 'state'}
				]
				,content: null
			}
			,items: []
			,item: {

			}

			,item_payment: []
			,summary: {}
			,is_modal: false
			,modal_option: {}
			,is_modal3: false
			,modal_option3: {}
			,is_modal2: false
			,modal_option2: {}
			,is_all: false
			,item_confirm: {
				state: ''
				, approval: ''
				, idx: []
				, reason: ''
			}
            ,settlementIds: []
		}
	}
	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){
                for (let i = 0; i < self.codes.auto_state.length; i++) {
                    if (item.state === self.codes.auto_state[i].code) {
                        item.state_name = self.codes.auto_state[i].name
                        item.state_color = self.codes.auto_state[i].color
                        break
                    }
                }
                for (let i = 0; i < self.codes.settlement_agency_type.length; i++) {
                    if (item.settlementType === self.codes.settlement_agency_type[i].code) {
                        item.type_name = self.codes.settlement_agency_type[i].name
                        break
                    }
                }
				return item
			})
		}
		,select_items: function(){
			let items = []
			this.items.filter(function(item){
				if(item.is_select){
					items.push(item.settlementId)
				}
			})
			return items
		}
	}
	,methods: {
        getData: async function () {
            try {
                this.$layout.onLoading()
                this.$set(this.search, 'startDate', this.search.sDate)
                this.$set(this.search, 'endDate', this.search.eDate)

                if(this.search.search_type) {
                    this.search.keywordType = this.search.search_type
                } else {
                    delete this.search.keywordType
                }
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'calculate/shop/manual'
                    , data: this.search
                })

                if (result.success) {
                    console.log(result.data,'manual')
                    this.items = result.data.data
                    this.search.total_count = result.data.totalCount
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }

                const result2 = await this.$Axios({
                    method: 'get'
                    , url: 'calculate/shop/manual/statistics'
                    , data: this.search
                })

                if (result2.success) {
                    console.log(result2.data, 'list')
                    this.summary = result2.data
                } else {
                    this.$layout.setNotify( {type: 'error', message: result2.message})
                }

            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , toDetail: function (item) {
            console.log(item,'to detail')
            let query = {
                keyword: item.shopName,
                sDate: item.paymentDate.split('T')[0],
                eDate: item.paymentDate.split('T')[0],
                search_date_type: 'free',
            }
            query = new URLSearchParams(query).toString()

            if(item.settlementType === 'QUICK'){
                this.$tabs.open('/Quick/List?search_type=3&' + query, false, true)
            } else if(item.settlementType === 'MANUAL'){
                this.$tabs.open('/Payment/List?paymentType=0&search_type=5&' + query, false, true)
            } else if(item.settlementType === 'MONTHLY'){
                this.$tabs.open('/Monthly/Payment/List?search_type=1&' + query, false, true)
            }

        }
        , reset: function () {
            this.search = {
                page: 1
                , size: 10
                , search_type: ''
                , search_value: ''
                , state: ''
                , sDate: this.date.getToday('-')
                , eDate: this.date.getToday('-')
                , search_date_type: 'today'
                , distributorIdx: this.user.distributorIdx
                , branchIdx: this.user.branchIdx
                , agencyIdx: this.user.agencyIdx
                , resellerIdx: this.user.resellerIdx
                , total_count: 0
            }

            this.getData()
        }
        , getSearch: function (page) {

            if (page) {
                this.search.page = page
            }

            this.getData()
        }

        , toExcel: async function () {
            try{
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    ,url: 'calculate/shop/manual/excel'
                    ,data: this.search
                })

                if(result.success){
                    let excelItem = result.data
                    excelItem.forEach((item) => {
                        for (let i = 0; i < this.codes.auto_state.length; i++) {
                            if (item.state === this.codes.auto_state[i].code) {
                                item.state = this.codes.auto_state[i].name
                            }
                        }
                        for (let i = 0; i < this.codes.settlement_agency_type.length; i++) {
                            if (item.settlementType === this.codes.settlement_agency_type[i].code) {
                                item.settlementType = this.codes.settlement_agency_type[i].name
                            }
                        }
                    })
                    this.excel_data.content = excelItem
                    this.is_excel = true
                }else{
                    this.$layout.setNotify( { type: 'error', message: result.message})
                }
            }catch(e){
                this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
                console.log(e)
            }finally {
                this.$layout.offLoading()
            }
        }
        , setSearchDateType: function (type) {
            this.search.search_date_type = type
        }
        , close: function () {
            this.is_modal = false
            this.is_modal2 = false
            this.is_modal3 = false
        }
        , cancel: async function () {

        }
        , isConfirm: function () {
            if (this.select_items.length <= 0) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.not_select})
                return
            }
            if (this.item_confirm.state === '') {
                this.$layout.setNotify( {type: 'error', message: '처리상태를 선택하세요'})
            } else {

                let status_name = ''

                for (let i = 0; i < this.codes.change_settlement_state.length; i++) {
                    if (this.item_confirm.state == this.codes.change_settlement_state[i].code) {
                        status_name = this.codes.change_settlement_state[i].name
                    }
                }
                this.is_modal3 = true
                this.modal_option3.content = '선택한 항목을 ' + status_name + ' 처리 하시겠습니까?'
            }
        }
        , doConfirm: async function () {
            try {
                this.$layout.onLoading()
                let settlementIdsList = []
                for (let i = 0; i < this.select_items.length; i++) {
                    settlementIdsList = settlementIdsList.concat(this.select_items[i])
                }
                const result = await this.$Axios({
                    method: 'put'
                    , url: 'calculate/shop/manual'
                    , data: {
                        settlementIds: settlementIdsList
                        , settlementState: this.item_confirm.state
                        , reason: this.item_confirm.reason
                    }
                })

                if (result.success) {
                    this.getSearch()
                    this.$layout.setNotify( {type: 'success', message: this.$language.common.success})
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }

                this.is_all = false
            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.close()
                this.$layout.offLoading()
            }
        }
        , setAgency: function (agency) {
            this.search.branchIdx = this.user.branchIdx ? this.user.branchIdx : agency.branchList
            this.search.distributorIdx = this.user.distributorIdx ? this.user.distributorIdx : agency.distributorList
            this.search.agencyIdx = this.user.agencyIdx ? this.user.agencyIdx : agency.agencyList
            this.search.resellerIdx = this.user.resellerIdx ? this.user.resellerIdx : agency.resellerList
            this.getSearch(this.search.page)
        }
        , setNotify: function ({type, message}) {
            this.$layout.setNotify( {type: type, message: message})
        }

    }
    ,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.distributorIdx = this.user.distributorIdx
        this.branchIdx = this.user.branchIdx
        this.agencyIdx = this.user.agencyIdx
        this.resellerIdx = this.user.resellerIdx
        this.$layout.onLoad(this.program)
		this.getData()
	}
	,watch: {
		is_all:{
			handler: function(call){
				let self = this
				this.items.filter(function(item){
					self.$set(item, 'is_select', call)
				})
			}
		}
		,'search.search_date_type': {
			immediate: true
			, handler: function(call){
                if (!this.$layout) {
                    this.$layout = this.$root.$children[0].$children[0]
                    this.user = this.$layout.user
                }
				let today = this.date.getToday('-')
				this.search.eDate = today
				switch(call){
					case 'today':
						this.search.sDate = today

						this.getData()
						break
					case 'weekly':
						this.search.sDate = this.date.getLastDate(today, 7, '-')

						this.getData()
						break
					case 'month':
						this.search.sDate = this.date.getLastDate(today, 30, '-')

						this.getData()
						break
					case '3':
						this.search.sDate = this.date.getLastDate(today, 90, '-')
						break
					case '6':
						this.search.sDate = this.date.getLastDate(today, 180, '-')
						break
				}
			}
		}
	}
}
</script>

<style>
td {
    word-break: break-all;
}
.bg-icon-quick { background: url('../../assets/images/icon/ico_quick.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-rent { background: url('../../assets/images/icon/ico_rent.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-paper { background: url('../../assets/images/icon/icon-paper.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-calc { background: url('../../assets/images/icon/icon-calc.svg') no-repeat 10px center; padding-left: 70px;}
</style>